export default {
  color: `betaLight`,
  textDecoration: `none`,
  userSelect: `none`,
  cursor: `default`,
  whiteSpace: `pre`,
  p: 0,
  ':visited': {
    color: 'betaLight'
  },
  ':hover': {
    color: 'betaLight'
  }
}
