import theme from '@solid-ui-theme'
// import sizes
import sizes from '@solid-ui-theme/sizes'

const customSizes ={
  ...theme,
  sizes: {
    ...sizes,
    container: 2000
  }
}

export default customSizes
