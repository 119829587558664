import React from 'react'
import { Link } from 'theme-ui'
import google from '../../assets/google-play-badge.svg'
import googleWhite from '../../assets/google-play-badge-white.svg'
import apple from '../../assets/apple-store-badge.svg'
import appleWhite from '../../assets/apple-store-badge-white.svg'
import linkedin from '../../assets/ik-linkedin.svg'
import twitter from '../../assets/ik-twitter.svg'
import github from '../../assets/ik-github.svg'

const styles = {
  button: {
    display: `inline-block`,
    width: [`40px`, `40px`],
    height: [`24px`, `24px`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center center`,
    backgroundSize: `contain`,
    backgroundColor: `transparent`,
    transition: `all 250ms ease`,
    p: 0,
    ':hover': {
      opacity: 0.8
    }
  },
  google: {
    backgroundImage: `url(${google})`
  },
  'google-white': {
    backgroundImage: `url(${googleWhite})`
  },
  apple: {
    backgroundImage: `url(${apple})`
  },
  'apple-white': {
    backgroundImage: `url(${appleWhite})`
  },
  linkedin: {
    backgroundImage: `url(${linkedin})`
  },
  'twitter': {
    backgroundImage: `url(${twitter})`
  }
  ,
  'github': {
    backgroundImage: `url(${github})`
  }
}

const AppButton = ({ variant, children, link }) => (
  <Link
    variant={null}
    sx={{
      ...styles.button,
      ...styles[variant]
    }}
    href={link}
    target='_blank'
    rel='noreferrer'
    alt={children}
    title={children}
  />
)

export default AppButton
