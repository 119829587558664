export default {
  variant: 'cards.primary',
  '@media (hover: hover) and (pointer: fine)': {
    ':hover': {
      transform: `translateY(-0.25rem)`,
      /* boxShadow: `0 0 25px rgba(140,152,164,.3)` */
      boxShadow: "0 0px 350px 5px #e3d6a7, 0 5px 10px -7px black"
    }
  }
}
