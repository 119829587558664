import common from './common'

export default {
  ...common.button,
  color: `omegaDark`,
  '::after': {
    ...common.button['::after'],
    bg: `alpha`,
    borderColor: `alpha`
  }
}
