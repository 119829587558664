import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentText from '@solid-ui-components/ContentText'
import ListItem from '@solid-ui-components/ListItem'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  logo: {
    width: [190, 250],
    height: [92, 100]
  }
}
const iconProps = {
  color: 'primary',
  style: {
    height: 20,
    width: 20,
  },
}
const FeaturesBlock06 = ({ content: { text = [], collection, buttons } }) => (
  <Container sx={{ textAlign: `left` }}>
    <Box>
      <ContentText content={text} />
    </Box>
    {collection && (
      <>
        <Divider />
        <>
          <Flex sx={{ justifyContent: `center`, flexWrap: `wrap`, m: 2 }}>
            {collection.map(({ container, ...props }, index) => (
              <Box
                key={`item-${index}`}
                sx={{ flexBasis: [`1`, `1/2`, null, `1/2`], p: 4 }}
              >
                <ContentContainer content={container}>
                  <ListItem
                    {...props}
                    iconProps={
                      { round: false }}
                    horizontal
                    left
                    //size={1000}
                  />
                </ContentContainer>
              </Box>
            ))}
          </Flex>
        </>
      </>
    )}
    {buttons && (
      <>
        <Divider space={3} />
        <ContentButtons content={buttons} />
      </>
    )}
  </Container>
)

export default WithDefaultContent(FeaturesBlock06)
