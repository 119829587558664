export default {
  bg: `contentBg`,
  borderRadius: `lg`,
  transition: `
    transform 250ms ease,
    box-shadow 250ms ease,
    color 250ms ease
  `,
  /* boxShadow: `0 0 35px rgba(140,152,164,.125)` */
  boxShadow: "0 0px 60px 5px #e3d6a7, 0 5px 10px -7px black"
}
