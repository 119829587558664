// Importing default theme color object to mutate it
import defaultColors from '@ikimera/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from '@ikimera/solid-ui-theme/src/color-preset'

/* const customColors = {
  ...defaultColors,
  modes: {
    ...defaultColors.modes,
    // New color mode
    ikimeraColorMode: {
      // Alpha (main) shade with purple
      alphaLighter: colors.gold[200],
      alphaLight: colors.gold[300],
      alpha: colors.gold[400],
      alphaDark: colors.gold[500],
      alphaDarker: colors.gold[600],
      // Beta (secondary) shade with pink
      betaLighter: colors.amber[50],
      betaLight: colors.amber[100],
      beta: colors.amber[200],
      betaDark: colors.amber[300],
      betaDarker: colors.amber[400],
    }
  }
}
export default customColors */

export default {
  ...defaultColors,
        // Alpha (main) shade with purple
        alphaLighter: colors.gold[200],
        alphaLight: colors.gold[300],
        alpha: colors.gold[400],
        alphaDark: colors.gold[500],
        alphaDarker: colors.gold[600],
        // Beta (secondary) shade with pink
        betaLighter: colors.gold[50],
        betaLight: colors.gold[100],
        beta: colors.gold[200],
        betaDark: colors.gold[300],
        betaDarker: colors.gold[400]
}