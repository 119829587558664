export default {
  layout: {
    flexDirection: 'column',
    height: `100vh`
  },
  body: {
    flex: `1 1 auto`,
    // backgroundImage: `linear-gradient(90deg, rgba(23,24,40,0.7) 100%, rgba(90,82,70,0.7) 0%)`, //slm - background color, previous: backgroundImage: `linear-gradient(90deg, rgba(90,82,70,0.7) 0%, rgba(23,24,40,0.7) 100%)`, 
    backgroundImage: `linear-gradient(90deg, rgba(23,24,40,0.7) 0%, rgba(90,82,70,0.7) 100%)`, 
    backgroundAttachment: 'fixed', // This line makes sure the gradient stays in place while scrolling.
  },

  /** Containers */
  container: {
    maxWidth: `container`,
    px: [3, 4]
  },
  narrow: {
    maxWidth: `narrow`,
    px: [3, 4]
  },
  wide: {
    maxWidth: `wide`,
    px: [3, 4]
  },
  full: {
    maxWidth: `none`
  },

  /** Sticky Box */
  sticky: {
    position: `sticky`,
    top: 4
  }
}
